<template>
  <div
    class="fixed z-60 top-2 lg:top-4 left-1/2 w-[calc(100%_-_1rem)]"
    @click.prevent.stop="toastAction()"
  >
    <div class="rounded-2xl p-2 bg-slate-600 border border-slate-300 flex left-1/2 -translate-x-1/2 overflow-hidden max-w-lg cursor-pointer drop-shadow-2xl">
      <div class="w-8 h-8 mr-2 shrink-0 flex items-center justify-center">
        <Spinner v-if="ntxType === 'waiting'" class="!py-0 animate-fade-in"/>
        <span v-else-if="ntxType === 'success'" class="icon-ico-tick text-green text-3xl font-bold animate-fade-in"/>
        <img
          v-else
          src="~/assets/img/logos/MetaWin-icon-white-cyan.png"
          alt="MetaWin logo"
          width="40"
          height="40"
          class="m-0 w-full h-auto animate-fade-in"
        >
      </div>
      <transition name="fade-fast" mode="out-in">
        <div
          :key="contentRefreshKey"
          class="text-sm lg:text-base text-white w-full animate-fade-in self-center"
        >
          <h4 v-if="title" class="font-bold leading-tight mb-0.5 lg:mb-0">{{ title }}</h4>
          <div v-if="content" class="text-xs lg:text-sm leading-tight" v-html="content"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
/*
  Universal toast notification using UI store to display content and modify behavior
  use toastNotification object in UI store to trigger toast.
  Example params:
    type: "waiting" // waiting, success, info
    title: "Some title",
    content: "This notification will not close automatically. Click will take you to the wallet page",
    action: { type: 'redirect', destination: '/wallet' },
    action: { type: 'custom', destination: 'showWalletModal' },
    closeAfter: null,
    delayed: true
*/
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();
const {
  toastNotification,
  showWalletModal,
} = storeToRefs(uiStore);

const timeout = ref(null);
const contentRefreshKey = ref(0);

const content = computed(() => toastNotification.value?.content);
const title = computed(() => toastNotification.value?.title);
const closeAfter = computed(() => toastNotification.value?.closeAfter);
const action = computed(() => toastNotification.value?.action);
const ntxType = computed(() => toastNotification.value?.type);

if (closeAfter.value) {
  setCloseTimeout(closeAfter.value);
}

async function toastAction() {
  if (action.value?.type === 'redirect' && action.value?.destination) {
    await navigateTo(action.value.destination);
  } else if (action.value?.type === 'custom' && action.value?.destination) {
    if (action.value.destination === 'showWalletModal') {
      showWalletModal.value = true;
    }
  }
  close();
}

function close() {
  toastNotification.value = null;
}

function setCloseTimeout(time) {
  timeout.value && clearTimeout(timeout.value);
  if (!time) { return; }
  timeout.value = setTimeout(() => {
    close();
  }, time);
}

onUnmounted(() => {
  timeout.value && clearTimeout(timeout.value);
});

watch(
  () => toastNotification.value,
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal && oldVal) {
      setCloseTimeout(this.closeAfter);
      contentRefreshKey.value++;
    }
  }
);
</script>
